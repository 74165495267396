import { Component, OnInit, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ConsoleToggleService } from './service/console.log.service';
import { environment } from '../environments/environment';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { UtilsService } from 'src/app/service/utils.service';
import { initializeApp } from 'firebase/app';
import { PrintScreenService } from 'src/app/service/print-screen.service';
import { onBackgroundMessage } from 'firebase/messaging/sw';
import { EventEmitterService } from './service/event-emitter.service';
import { Subscription } from 'rxjs';
import { SendCallingMessageService } from './service/send-calling-message.service';
import { NotificationService } from './service/notification.service';
import { ColorsService } from 'src/app/service/colors.service';
import { CommunicationService } from 'src/app/service/communication.service';
import { ColabRedirectService } from 'src/app/service/colab-redirect.service';

initializeApp(environment.firebase);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Redium';
  message: any = null;
  notification: boolean = false;
  all_users: any;
  profileImage: string;
  macAgent: boolean = false;
  showCallingPop: boolean = false;
  isCalled: boolean = false;
  isRejected: boolean = false;
  isPIPVew: boolean = false;
  isCalling: boolean = false;

  width: string = '100%';
  height: string = '100vh';
  left: string = '0%';
  top: string = '0%';

  position = { x: 0, y: 0 };
  isDragging = false;
  dragStart = { x: 0, y: 0 };
  callTime: any;
  callName: string;
  userPicture: string = '';
  callingSendBirdData: any;

  startCallSub: Subscription;
  callJoinSub: Subscription;
  closeSub: Subscription;
  joinInomingSub: Subscription;
  callLeaveSub: Subscription;
  meetingUrl: any;
  callingName: string = null;
  isTeamCalling: boolean = false;
  callType: string = null;
  channel: any;
  customTypes = [];
  joinStrip: boolean = false;
  isStrip: number = 0;
  displayName: string = '';
  channelType: string = '';
  callPickuptimer: any;
  allowedScreen: boolean = true;
  userSendbirdId: string;
  stopSound: boolean = false;
  menberObj: any;
  isAnnouncement: boolean = false;
  page: any;

  constructor(
    private router: Router,
    private consoleToggleService: ConsoleToggleService,
    private UtilsService: UtilsService,
    private PrintScreenService: PrintScreenService,
    private eventEmitterService: EventEmitterService,
    public scmservice: SendCallingMessageService,
    private notificationService: NotificationService,
    private colorService: ColorsService,
	private comm: CommunicationService,
	private colabRedirect: ColabRedirectService,
  ) {
    this.consoleToggleService.disableConsoleInProduction();
	
	this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/dashboard/members-collab') { 
		  this.page = 'colablist';
	    } else if (event.url === '/dashboard/team-collab') {
		  this.page = 'announcements';
        } else {
		  this.page = 'dashboard';
		}
      }
    });

    //-- Event Emitters AND associated coding should be removed
    this.startCallSub = this.eventEmitterService.startCallEmitter.subscribe(
      (status) => {
        // this.displayName = status?.teamName.toString();
        // this.profileImage = status?.profileImage?.toString();
        // this.meetingUrl = status?.ch_url;
        // this.isCalled = false;
        // this.isTeamCalling = status?.isTeam;

        // if (!status?.isTeam) {
          // this.opponentJoinedCall(status.ch_url.toString());
          // this.isCalling = false;
        // } else {
          // this.isCalling = true;
        // }
      }
    );

    this.callJoinSub = this.eventEmitterService.callJoin.subscribe((status) => {
      // let filterData = this.all_users?.filter(
        // (el) =>
          // el.sendbird_user_details.user_id === status.message.sender.userId
      // );

      // this.profileImage =
        // filterData && filterData.length > 0 ? filterData[0].userpicture : null;
      // this.displayName = status.message.sender.nickname;
      // if (this.isCalling) {
        // this.opponentJoinedCall(status.ch_url.toString());
        // this.isCalling = false;
        // this.joinStrip = false;
      // } else {
        // this.showCallingPop = false;
        // this.meetingUrl = status.ch_url.toString();
        // this.joinStrip = true;
      // }
    });

    this.closeSub = this.eventEmitterService.jitsiClose.subscribe((status) => {
      // this.isCalled = false;
      // this.isCalling = false;
      // this.showCallingPop = false;
      // this.joinStrip = false;
    });

    this.eventEmitterService.callJoined.subscribe((status) => {
      // this.joinCall(status.ch_id);
      // this.joinStrip = false;
    });

    this.eventEmitterService.autoCallReject.subscribe((status) => {
      // localStorage.removeItem('call_status');
      // this.isCalling = false;
      // this.showCallingPop = false;
      // this.joinStrip = false;
    });

    this.callLeaveSub = this.eventEmitterService.callLeave.subscribe(
      (status) => {
        // this.isCalled = false;
        // this.isCalling = false;
        // this.showCallingPop = false;
        // this.leaveMeeting();
        ////this.eventEmitterService.setCallButton.emit(true);
      }
    );
  }

  ngOnInit() {
    this.userSendbirdId = localStorage.getItem('user_id');
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
    
    // Register the service worker for firebase messaging
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered with scope:', registration.scope);
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    } else {
      console.log('Service Worker is not supported in this browser.');
    }
    
    this.macAgent = this.isMacOS() ? true : false;

    // Added browser Specific condition for getting the push request prompt
    let browserName = this.getBrowserName();
    if(browserName !== 'Safari' && browserName !== 'Firefox'){
      this.checkNotificationPermission();
      this.requestPermission();
    }
    this.listen();
    let formChanged = true;

    window.addEventListener('storage', (event) => {
      if (event.storageArea == localStorage) {
        let token = localStorage.getItem('fullname');
        if (token == undefined) {
		localStorage.setItem('clear-push-token','true');
          // Perform logout
          //Navigate to login/home
          this.router.navigate(['/login']);
        }
      }
    });
	if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'NOTIFICATION_CLICKED') {
          const redirectUrl = event.data.redirectUrl;
          console.log('Notification clicked! Redirecting to:', redirectUrl);
          
          // Trigger your custom event or perform redirection logic
          	this.loadNotifications();
        }
      });
    }
  }

  // Detect the browser name
  getBrowserName() {
    const detechBrowser = navigator.userAgent;
    if (detechBrowser.indexOf('Chrome') > -1 && detechBrowser.indexOf('Safari') > -1) {
      return 'Chrome';
    } else if (detechBrowser.indexOf('Firefox') > -1) {
      return 'Firefox';
    } else if (detechBrowser.indexOf('MSIE') > -1 || detechBrowser.indexOf('Trident') > -1) {
      return 'Internet Explorer';
    } else if (detechBrowser.indexOf('Edge') > -1) {
      return 'Edge';
    } else if (detechBrowser.indexOf('Safari') > -1) {
      return 'Safari';
    } else {
      return 'Unknown Browser';
    }
  }

  joinFromStrip() {
    this.joinCall(this.meetingUrl);
    this.isStrip = 1;
    //this.meetingUrl = '';
    this.joinStrip = false;
  }

  closeStrip() {
    this.meetingUrl = '';
    this.joinStrip = false;
  }

  checkNotificationPermission(): void {
    if (!this.notificationService.isNotificationSupported()) {
      alert('Your browser does not support notifications.');
      return;
    }

    const permission = this.notificationService.getNotificationPermission();
    console.log('notificationpermision:', permission);
    if (permission === 'default') {
      // Request permission if not already granted or denied
      this.notificationService.requestNotificationPermission();
    }
  }

  requestPermission() {
    // Added checking if the service worker is ready or not. If ready then it will get the token from firebase
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then((registration) => {
          console.log('Service Worker is active:', registration);
          const messaging = getMessaging();
          return getToken(messaging, {
            vapidKey: environment.firebase.vapidKey,
            serviceWorkerRegistration: registration // Pass the active service worker
          });
        })
        .then((currentToken) => {
          if (currentToken) {
            console.log('Token received:', currentToken);
            localStorage.setItem('push-notification-token', currentToken);
          } else {
            console.log('No registration token available.');
          }
        })
        .catch((err) => {
          console.log('Error retrieving token:', err);
        });
    } else {
      console.log('Service Worker is not supported.');
    }
    
  }

  listen() {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      //console.log('Message received. ', payload);
      this.message = payload?.data ? JSON.parse(payload?.data.sendbird) : null;
      console.log('Message: ', this.message);
	  
      this.all_users = JSON.parse(localStorage.getItem('all_users'));
	    this.processPushNotification();
    });
  }
  
  processPushNotification() {
	let currentCallStatus: boolean =
        localStorage.getItem('call_status') == 'CALL-JOINED' ? true : false;

      if (this.message.channel.name.toLowerCase() == 'group channel') {
        this.isTeamCalling = true;
		this.isAnnouncement = false;
        let filterData = this.all_users?.filter(
          (el) => el.sendbird_user_details.user_id === this.message.sender.id
        );
        this.menberObj = filterData;
        this.profileImage =
          filterData && filterData.length > 0
            ? filterData[0].userpicture
            : null;
        this.displayName = this.message.sender.name;
      } else {
        this.menberObj = this.message;
        this.isTeamCalling = false;
		this.isAnnouncement = this.message.channel.name.toLowerCase() == 'announcements'?true:false;
        this.displayName = this.message.channel.name;
        this.profileImage = null;
      }

      // if (
        // (this.message.custom_type == 'CALL-STARTED' ||
          // this.message.custom_type == 'CALL-JOINED') &&
        // !currentCallStatus
      // ) {
        // this.showCallingPop = true;
        // this.meetingUrl = this.message.channel.channel_url;
      // } else if (
        // this.message.custom_type == 'CALL-STARTED' &&
        // currentCallStatus
      // ) {
        // this.callType = 'call_user_busy';
        // if (this.isTeamCalling)
          // this.scmservice.sbConnect(this.meetingUrl, this.callType);
      // }

      // if (this.message.custom_type == 'CALL-STARTED') {
        // this.callPickuptimer = setTimeout(() => {
          // this.notification = false;
          // this.showCallingPop = false;
          // this.isCalling = false;
          // this.isCalled = false;
          // this.callType = 'call_missed';
          // if (this.isTeamCalling)
            // this.scmservice.sbConnect(this.meetingUrl, this.callType);
        // }, 30000);
      // }

      // // if (this.message.custom_type == 'CALL-ENDED') {
      // //   this.showCallingPop = false;
      // //   this.isCalling = false;
      // //   this.isCalled = false;
      // // }
      // if (this.message.custom_type == 'CALL-MISSED') {
        // this.showCallingPop = false;
        // this.isCalling = false;
        // this.isCalled = false;
      // }

      this.showPushNotificatonMessage();
  }
	
  emptyCollab() {
    this.notification = false;
    localStorage.setItem('allCollab', 'true');
    if (Object.keys(this.menberObj)?.length > 0) {
      let data = this.menberObj;
      if (this.isTeamCalling) {
        this.colabRedirect.userRedirect(data[0]);
      } else if(!this.isTeamCalling && this.isAnnouncement) { 
	    const globalGroup = JSON.parse(localStorage.getItem('globalgroup'));
		this.colabRedirect.globalGroupClick(globalGroup[0]);
		this.comm.loadAnnouncementOnPushNotification$.next('true');
	  } else {
        data.sendbird_team_details = data.channel;
        data.name = data.channel.name;
        data.isDistinct = false;
        this.colabRedirect.teamRedirect(data);
      }
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyDownEvent(e: KeyboardEvent) {
    if (this.macAgent) this.handleKeyboardEvent(e);
  }

  // Handle keyup event
  @HostListener('document:keyup', ['$event'])
  handleKeyUpEvent(e: KeyboardEvent) {
    if (!this.macAgent) this.handleKeyboardEvent(e);
  }

  handleKeyboardEvent(e: KeyboardEvent) {
    const c = e.keyCode || e.charCode;
    const blockScreenPrint = localStorage.getItem('IS-ALLOWED-SCREENSHOT-ORG');
    if (blockScreenPrint == 'N' && c == 44 && !this.macAgent) {
      alert('Print screen not permitted');
      navigator.clipboard.writeText('not allowed');
      e.preventDefault();
      e.stopPropagation();
    } else if (
      blockScreenPrint == 'N' &&
      e.shiftKey &&
      e.keyCode == 83 &&
      !this.macAgent
    ) {
      this.PrintScreenService.printScreenAllowed();
      e.preventDefault();
      e.stopPropagation();
    } else if (
      this.macAgent &&
      blockScreenPrint == 'N' &&
      ((e.metaKey && e.keyCode == 16) || (e.metaKey && e.shiftKey))
    ) {
      this.PrintScreenService.printScreenAllowed();
      e.preventDefault();
      e.stopPropagation();
    }
  }

  isMacOS() {
    return (
      /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.platform) ||
      /Mac OS X/.test(navigator.userAgent)
    );
  }

  joinCall(meetingUrl) {
    this.meetingUrl = meetingUrl;
    this.showCallingPop = false;
    this.isCalled = true;
    this.callType = 'call_joined';
    localStorage.setItem('call_status', this.callType);
    localStorage.setItem('call_status', 'call_joined');
  }

  opponentJoinedCall(meetingUrl) {
    this.meetingUrl = meetingUrl;
    this.showCallingPop = false;
    this.isCalled = true;
    this.callType = 'call_joined';
    localStorage.setItem('call_status', 'call_joined');
  }

  acceptCall(message: any) {
    clearInterval(this.callPickuptimer);
    this.channelType = message.channel_type;
    this.message = message;
    this.meetingUrl = message.channel.channel_url;
    this.showCallingPop = false;
    this.isCalled = true;
    if (this.isTeamCalling) {
      this.callType = 'call_joined';
      this.scmservice.sbConnect(this.meetingUrl, this.callType);
    }
    localStorage.setItem('call_status', 'call_joined');
    this.eventEmitterService.setCallButton.emit(true);
  }

  rejectCall(message: any) {
    clearInterval(this.callPickuptimer);
    this.showCallingPop = false;
    this.meetingUrl = message.channel.channel_url;
    this.isCalling = false;
    //this.eventEmitterService.jitsiClose.emit(true);
    if (this.isTeamCalling) {
      this.callType = 'call_rejected';
      this.scmservice.sbConnect(this.meetingUrl, this.callType);
    }
  }

  //-------------- jitsi dragging
  changeToPIPView() {
    this.isPIPVew = true;
    this.width = '24%';
    this.height = '40vh';
    this.top = '50%';
    this.left = '75%';
  }

  changeFromPIPView() {
    this.position.x = 0;
    this.position.y = 0;
    this.isPIPVew = false;
    this.width = '100%';
    this.height = '100vh';
    this.top = '0%';
    this.left = '0%';
  }

  onMouseDown(event: MouseEvent) {
    if (this.isPIPVew == true) {
      this.isDragging = true;
      this.dragStart.x = event.clientX - this.position.x;
      this.dragStart.y = event.clientY - this.position.y;
    }
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging && this.isPIPVew == true) {
      this.position.x = event.clientX - this.dragStart.x;
      this.position.y = event.clientY - this.dragStart.y;
    }
  }

  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }

  leaveMeeting() {
    this.callType = 'call_ended';
    this.scmservice.sbConnect(this.meetingUrl, this.callType);
    localStorage.removeItem('call_status');
    this.isCalled = false;
    this.isCalling = false;
    this.showCallingPop = false;
    this.profileImage = null;
    this.displayName = null;
    this.meetingUrl = null;
    this.isTeamCalling = false;
    this.eventEmitterService.callEnd.emit(true);
  }

  participantLeft() {
    alert('participantLeft');
    localStorage.removeItem('call_status');
    this.isCalled = false;
    this.isCalling = false;
    this.showCallingPop = false;
    this.profileImage = null;
    this.displayName = null;
    this.meetingUrl = null;
    this.isTeamCalling = false;
    this.eventEmitterService.setCallButton.emit(true);
  }

  redirectPage(page) {
    this.changeToPIPView();
    if (page == 'home') this.router.navigate(['/dashboard']);
    else if (page == 'chat')
      this.router.navigate(['/dashboard/members-collab']);
    else if (page == 'calendar')
      this.router.navigate(['/dashboard/attendence-report']);
  }

  setTimer(event) {
    this.callTime = event;
  }

  ngOnDestroy(): void {
    if (this.startCallSub) {
      this.startCallSub.unsubscribe();
    }
    if (this.callJoinSub) {
      this.callJoinSub.unsubscribe();
    }
    if (this.closeSub) {
      this.closeSub.unsubscribe();
    }
    if (this.joinInomingSub) {
      this.joinInomingSub.unsubscribe();
    }

    if (this.callLeaveSub) {
      this.callLeaveSub.unsubscribe();
    }
  }

  showPushNotificatonMessage() {
	const currentTeam = localStorage.getItem('selectedTeamName');
	const authToken = localStorage.getItem('authentication-token');

    if(this.page == 'colablist')
	this.allowedScreen = false;
	else if (this.page == 'announcements' && this.isAnnouncement && currentTeam == 'Announcements')
	this.allowedScreen = false;
	else if (authToken == null)
	this.allowedScreen = false;
	else {
	 this.allowedScreen = true;
	}
	
    if (
      this.message.custom_type != 'CALL-ENDED' &&
      this.message.custom_type != 'CALL-REJECTED' &&
      this.message.custom_type != 'CALL-MISSED' &&
      this.message.custom_type != 'CALL-STARTED' &&
      this.message.custom_type != 'CALL-JOINED' &&
      this.allowedScreen &&
      this.message.sender.id !== this.userSendbirdId
    ) {
      this.notification = true;
      setTimeout(() => {
        this.notification = false;
      }, 8000);
    }
  }
  participantJoinedCountMoreThanOnefunc(event) {
    this.stopSound = true;
  }

  closeCollab() {
    this.notification = false;
  }

  ngAfterViewInit() {
    this.all_users = JSON.parse(localStorage.getItem('all_users'));
    setTimeout(() => {
      this.all_users = JSON.parse(localStorage.getItem('all_users'));
    }, 8000);
  }
  
  // Function to get data from IndexedDB
	getDataFromIndexedDB(): Promise<any[]> {
	return new Promise((resolve, reject) => {
    const request = indexedDB.open('push-notification', 1); // Ensure version is correct

    request.onsuccess = function (event) {
      const db = (event.target as IDBRequest).result;
      const transaction = db.transaction('notifications', 'readonly');
      const store = transaction.objectStore('notifications');
      const getRequest = store.getAll(); // Retrieves all data from 'notifications' object store

      getRequest.onsuccess = function () {
        resolve(getRequest.result); // Resolve with the data
      };

      getRequest.onerror = function () {
        reject('Error retrieving data from IndexedDB'); // Reject if error occurs
      };
    };

    request.onerror = function (event) {
      reject('Error opening database for retrieval');
    };
  });
  }
  
  loadNotifications(): void { 
    this.getDataFromIndexedDB()
      .then((data) => {
	    let num = data?.length > 0 ? data?.length-1 : null;
		if(num != null) {
		const responsdata = data[num].body;
		this.message = responsdata != null ? JSON.parse(responsdata) : null;
		if(num > 20)
		this.clearDataFromIndexedDB();
		this.processPushNotification();
		}
      })
      .catch((error) => {
        console.error('Error loading notifications:', error);
      });
  }
  
  clearDataFromIndexedDB(): Promise<any[]> {
	return new Promise((resolve, reject) => {
    const request = indexedDB.open('push-notification', 1); // Ensure version is correct

    request.onsuccess = function (event) { 
      const db = (event.target as IDBRequest).result;
      const transaction = db.transaction('notifications', 'readwrite');
      const store = transaction.objectStore('notifications');
      const clearRequest = store.clear();
	  clearRequest.onsuccess = function() { 
	  };
    }
   });
  }
}  
  
