import { Injectable } from '@angular/core';
import { EventEmitterService } from './event-emitter.service';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  permission: string;
  constructor(private eventEmitterService: EventEmitterService,) {}

  // Check if notifications are supported in the browser
  isNotificationSupported(): boolean {
    return 'Notification' in window;
  }

  // Check the current notification permission
  getNotificationPermission(): NotificationPermission {
	this.permission = Notification.permission;
    return Notification.permission;
  }

  // Request notification permission from the user
  requestNotificationPermission(): void {
    if (this.isNotificationSupported()) {
      Notification.requestPermission().then((permission) => {
        console.log('Notification permission:', permission);
        if (permission === 'granted') {
          console.log('Permission granted');
          
          // Updating thr notification grant status
          this.eventEmitterService.isPushNotificationGranted.emit(true);
        } else {
          console.log('Permission denied');
        }
      });
    } else {
      console.error('Notifications are not supported in this browser.');
    }
  }

  // Show notification if permission is granted
  showNotification(title: string, options?: NotificationOptions): void {
     new Notification(title, options);
  }
}
